import type { NextPage } from 'next';
import { Link } from 'modules/I18n';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';

const Page500: NextPage = (): any => {
  const { whatsappContact, siteName } = usePublicRuntimeConfig();

  return (
    <>
      <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">{siteName}</span>
      </h1>
      <main className="boxed--sm prose">
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
        <p>You may have mistyped the address or the page may have moved.</p>
        <Link href="/">Go back home</Link>
        <p>
          <a href={whatsappContact} target="_blank" rel="noreferrer" className="link">
            WhatsApp
          </a>
        </p>
      </main>
    </>
  );
};

export default Page500;
